import * as apiUrl from '@/network/api-const'
import http from '@/network/http-tools'

const api = {
    login(params) {
        return http.fetchPost(apiUrl.loginUrl, params);
    },
    libraryPdfUrl(params) {
        return http.fetchPost(apiUrl.libraryPdfUrl, params);
    },
    getSpecTable(params) {
        return http.fetchPost(apiUrl.specTableUrl, params);
    },
    getNewSpecTable(params) {
        return http.fetchPost(apiUrl.newSpecTableUrl, params);
    },
    getSdsTable(params) {
        return http.fetchPost(apiUrl.sdsTableUrl, params);
    },
    getItemCodeList(params) {
        return http.fetchPost(apiUrl.getItemCodeListUrl, params);
    },
    getSdsItemCodeListUrl(params) {
        return http.fetchPost(apiUrl.getSdsItemCodeListUrl, params);
    },
    getPreview(params) {
        return http.fetchPost(apiUrl.getPreviewUrl, params);
    },
    getFileUrl() {
        return apiUrl.getFileUrl;
    },
    getFileManagementPageList(params) {
        return http.fetchPost(apiUrl.getFileManagementPageList, params);
    },
    batchAddLibrary(params) {
        return http.fetchPost(apiUrl.batchAddLibrary, params);
    },
    getLibraryInfo(params) {
        return http.fetchGet(apiUrl.getLibraryInfo + '/' + params);
    },
    qiNiuUploadFile(params) {
        return http.fetchUploadPost(apiUrl.qiNiuUploadFile, params);
    },
    libraryUploadFile(params) {
        return http.fetchUploadPost(apiUrl.libraryUploadFile, params);
    },
    deleteLibraryInfo(params) {
        return http.fetchPost(apiUrl.deleteLibraryInfo + '/' + params);
    },
    deleteLibraryByItemcode(params) {
        return http.fetchPost(apiUrl.deleteLibraryByItemcode + '/' + params);
    },
    handleSpec: {
        getDetail(params) {
            return http.fetchGet(apiUrl.handleSpecTableUrl , params);
        },
        getNewSpecDetail(params) {
            return http.fetchGet(apiUrl.handleNewSpecTableUrl + '/' + params.id);
        },
        getNewSpecAuditedDetail(params) {
            return http.fetchGet(apiUrl.handleNewSpecAuditedUrl + '/' + params.id);
        },
        getNewSpecAuditedLatestInfo(params) {
            return http.fetchGet(apiUrl.getNewSpecAuditedLatestInfoUrl + '/' + params.itemCode);
        },
        verifyNewSpec(params) {
            return http.fetchPost(apiUrl.newSpecVerifyUrl ,params);
        },
        verifyListNewSpec(params) {
            return http.fetchPost(apiUrl.newSpecVerifyListUrl ,params);
        },
        getSdsDetail(params) {
            return http.fetchGet(apiUrl.handleSdsTableUrl , params);
        },
        getDetailByItemCode(params) {
            return http.fetchGet(apiUrl.handleSpecDetailUrl + '/' + params);
        },
        getAllName(params) {
            return http.fetchPost(apiUrl.getAllName, params);
        },
        getInfoByItemCodes(params) {
            return http.fetchPost(apiUrl.getInfoByItemCodes, params);
        },
        getAllNameCount(params) {
            return http.fetchPost(apiUrl.getAllNameCount, params);
        },
        addItem(params) {
            return http.fetchPost(apiUrl.addSpecItemUrl, params);
        },
        addNewSpecItem(params) {
            return http.fetchPost(apiUrl.addNewSpecItemUrl, params);
        },
        editItem(params) {
            return http.fetchPost(apiUrl.editSpecItemUrl, params);
        },
        editNewSpecItem(params) {
            return http.fetchPost(apiUrl.editNewSpecItemUrl, params);
        },
        addSdsItem(params) {
            return http.fetchPost(apiUrl.addSdsItemUrl, params);
        },
        editSdsItem(params) {
            return http.fetchPost(apiUrl.editSdsItemUrl, params);
        },
        checkItemCode(params) {
            return http.fetchGet(apiUrl.checkItemCodeUrl + '/' + params.itemCode + '/' + params.id);
        },
        deleteItem(params) {
            return http.fetchPost(apiUrl.deleteItemUrl + '/' + params);
        },
        copyItem(params) {
            return http.fetchPost(apiUrl.copyItemUrl + '/' + params);
        },
        copySdsItem(params) {
            return http.fetchPost(apiUrl.copySdsUrl + '/' + params);
        },
        deleteSdsItem(params) {
            return http.fetchPost(apiUrl.deleteSdsItemUrl + '/' + params);
        },
        sdsMatchUrl(params) {
            return http.fetchPost(apiUrl.sdsMatchUrl, params);
        },
        updateDetailBySpecList(params) {
            return http.fetchPost(apiUrl.updateDetailBySpecList, params);
        },
        updateDetailBySdsList(params) {
            return http.fetchPost(apiUrl.updateDetailBySdsList, params);
        },
        batchUpdateItemCode(params) {
            return http.fetchPost(apiUrl.batchUpdateItemCode, params);
        },
        previewPDF(params) {
            return http.fetchPost(apiUrl.pdfPreviewUrl, params);
        }
    },
    handleComp: {
        getCompTable(params) {
            return http.fetchPost(apiUrl.compTableUrl, params);
        },
        getCompDetail(params) {
            return http.fetchGet(apiUrl.handleCompTableUrl , params);
        },
        addCompItem(params) {
            return http.fetchPost(apiUrl.addCompItemUrl, params);
        },
        editCompItem(params) {
            return http.fetchPost(apiUrl.editCompItemUrl, params);
        },
        copyCompItem(params) {
            return http.fetchPost(apiUrl.copyCompUrl + '/' + params);
        },
        deleteCompItem(params) {
            return http.fetchPost(apiUrl.deleteCompItemUrl + '/' + params);
        },
        getCompItemCodeListUrl(params) {
            return http.fetchPost(apiUrl.getCompItemCodeListUrl, params);
        },
        updateDetailByCompList(params) {
            return http.fetchPost(apiUrl.updateDetailByCompList, params);
        },
    },
    handleTps: {
        getOtherTable(params) {
            return http.fetchPost(apiUrl.otherTableUrl, params);
        },
        tpsUploadSave(params) {
            return http.fetchPost(apiUrl.tpsUploadSaveUrl, params);
        },
        getTpsDetail(params) {
            return http.fetchGet(apiUrl.tpsInfo, params);
        },
        deleteTpsItem(params) {
            return http.fetchPost(apiUrl.deleteTpsItemUrl + '/' + params);
        },
        getTpsItemCodeListUrl(params) {
            return http.fetchPost(apiUrl.getTpsItemCodeListUrl, params);
        },
    },
    verfiedItem(params) {
        return http.fetchPost(apiUrl.verfiedUrl, params);
    },
    verfiedSdsItem(params) {
        return http.fetchPost(apiUrl.verfiedSdsUrl, params);
    },
    logout(params) {
        return http.fetchPost(apiUrl.logoutUrl, params);
    },
    coa: {
        getCoaTableUrl(params) {
            return http.fetchPost(apiUrl.coaTableUrl, params);
        },
        addCoaUrl(params) {
            return http.fetchPost(apiUrl.addCoaUrl, params);
        },
        editCoaUrl(params) {
            return http.fetchPost(apiUrl.editCoaUrl, params);
        },
        handleCoaTableUrl(params) {
            return http.fetchGet(apiUrl.handleCoaTableUrl , params);
        },
        deleteCoaItemUrl(params) {
            return http.fetchPost(apiUrl.deleteCoaItemUrl + '/' + params);
        },
        batchDeleteUrl(params) {
            return http.fetchPost(apiUrl.batchDeleteUrl , params);
        },
        uploadCoaItem(params) {
            return http.fetchPost(apiUrl.uploadCoaItem, params);
        },
        getItemCodeCoaListUrl(params) {
            return http.fetchPost(apiUrl.getItemCodeCoaListUrl, params);
        },
        copyCoaItemUrl(params) {
            return http.fetchPost(apiUrl.copyCoaItemUrl + '/' + params);
        },
        verfiedCoaItem(params) {
            return http.fetchPost(apiUrl.verfiedCoaItem, params);
        },
    },
    handleCnSpec: {
        getCnSpecTableUrl(params) {
            return http.fetchPost(apiUrl.cnSpecTableUrl, params);
        },
        getDetail(params) {
            return http.fetchGet(apiUrl.getCnSpecItemUrl , params);
        },
        addItem(params) {
            return http.fetchPost(apiUrl.addCnSpecItemUrl, params);
        },
        editItem(params) {
            return http.fetchPost(apiUrl.editCnSpecItemUrl, params);
        },
        deleteItem(params) {
            return http.fetchPost(apiUrl.deleteCnSpecItemUrl + '/' + params);
        },
        copyCnSpecItem(params) {
            return http.fetchPost(apiUrl.copyCnSpecItemUrl + '/' + params);
        },
        getCnDetailByCnItemCode(params) {
            return http.fetchGet(apiUrl.getCnDetailByCnItemCode + '/' + params);
        },
        getZhCnItemCodeList(params) {
            return http.fetchPost(apiUrl.getZhCnItemCodeList, params);
        },
        cnverfiedUrl(params) {
            return http.fetchPost(apiUrl.cnverfiedUrl, params);
        },
    },
    handleCnSds: {
        getCnSdsTableUrl(params) {
            return http.fetchPost(apiUrl.cnSdsTableUrl, params);
        },
        getSdsDetail(params) {
            return http.fetchGet(apiUrl.getCnSdsItemUrl + '/' + params);
        },
        addSdsItem(params) {
            return http.fetchPost(apiUrl.addCnSdsItemUrl, params);
        },
        editSdsItem(params) {
            return http.fetchPost(apiUrl.editCnSdsItemUrl, params);
        },
        deleteSdsItem(params) {
            return http.fetchPost(apiUrl.deleteCnSdsItemUrl + '/' + params);
        },
        cnSdsMatchUrl(params) {
            return http.fetchPost(apiUrl.cnSdsMatchUrl, params);
        },
        copyCnSdsItem(params) {
            return http.fetchPost(apiUrl.copyCnSdsUrl + '/' + params);
        },
        getItemCodeCnSdsListUrl(params) {
            return http.fetchPost(apiUrl.getItemCodeCnSdsListUrl, params);
        },
        getCnDetailByCnSdsItemCode(params) {
            return http.fetchGet(apiUrl.getCnDetailByCnSdsItemCode + '/' + params);
        },
        cnverfiedSdsUrl(params) {
            return http.fetchPost(apiUrl.cnverfiedSdsUrl, params);
        },
    },
    getItemCodeCnListData(params) {
        return http.fetchPost(apiUrl.getItemCodeCnListUrl, params);
    },
    handleCnRawmaterial: {
        getCnRawmaterialList(params) {
            return http.fetchPost(apiUrl.getCnRawmaterialList, params);
        },
        getCnRawmaterialInfo(params) {
            return http.fetchGet(apiUrl.getCnRawmaterialInfo + '/' + params);
        },
        addCnRawmaterial(params) {
            return http.fetchPost(apiUrl.addCnRawmaterial, params);
        },
        updateCnRawmaterial(params) {
            return http.fetchPost(apiUrl.updateCnRawmaterial, params);
        },
        deleteCnRawmaterial(params) {
            return http.fetchPost(apiUrl.deleteCnRawmaterial + '/' + params);
        },
        getItemCodeCnRawmaterialList(params) {
            return http.fetchPost(apiUrl.getItemCodeCnRawmaterialList, params);
        },
        getCnRawmaterialTargetNameInfo(params) {
            return http.fetchGet(apiUrl.getCnRawmaterialTargetNameInfo + '/' + params);
        },
    },
    handleCnCoa: {
        getCnCoaTableUrl(params) {
            return http.fetchPost(apiUrl.cnCoaTableUrl, params);
        },
        getCnCoaInfo(params) {
            return http.fetchGet(apiUrl.getCnCoaInfo + '/' + params);
        },
        addCnCoaUrl(params) {
            return http.fetchPost(apiUrl.addCnCoaUrl, params);
        },
        editCnCoaUrl(params) {
            return http.fetchPost(apiUrl.editCnCoaUrl, params);
        },
        deleteCnCoaItem(params) {
            return http.fetchPost(apiUrl.deleteCnCoaItem + '/' + params);
        },
        getItemCodeCnCoaListUrl(params) {
            return http.fetchPost(apiUrl.getItemCodeCnCoaListUrl, params);
        },
        copyCnCoaItemUrl(params) {
            return http.fetchPost(apiUrl.copyCnCoaItemUrl + '/' + params);
        },
        cnCoaVerified(params) {
            return http.fetchPost(apiUrl.cnCoaVerified + '/' + params);
        },
        cnCoaComplete(params) {
            return http.fetchPost(apiUrl.cnCoaComplete + '/' + params);
        },
    }, 
    handleConversion: {
        conversionAdd(params) {
            return http.fetchPost(apiUrl.conversionAdd, params);
        },
        conversionConfirm(params) {
            return http.fetchPost(apiUrl.conversionConfirm, params);
        },
        conversionDelete(params) {
            return http.fetchPost(apiUrl.conversionDelete + '/' + params);
        },
        getConversionList(params) {
            return http.fetchPost(apiUrl.getConversionList, params);
        },
        conversionInfo(params) {
            return http.fetchGet(apiUrl.conversionInfo + '/' + params);
        },
        conversionPreview(params) {
            return http.fetchPost(apiUrl.conversionPreview, params);
        },
        conversionUpdate(params) {
            return http.fetchPost(apiUrl.conversionUpdate, params);
        },
    },
    handleTds: {
        tdsAdd(params) {
            return http.fetchPost(apiUrl.tdsAdd, params);
        },
        tdsConfirm(params) {
            return http.fetchPost(apiUrl.tdsConfirm, params);
        },
        tdsDelete(params) {
            return http.fetchPost(apiUrl.tdsDelete + '/' + params);
        },
        getTdsList(params) {
            return http.fetchPost(apiUrl.getTdsList, params);
        },
        tdsInfo(params) {
            return http.fetchGet(apiUrl.tdsInfo + '/' + params);
        },
        tdsUpdate(params) {
            return http.fetchPost(apiUrl.tdsUpdate, params);
        },
    },
    handleTestMethod: {
        testMethodAdd(params) {
            return http.fetchPost(apiUrl.testMethodAdd, params);
        },
        testMethodConfirm(params) {
            return http.fetchPost(apiUrl.testMethodConfirm, params);
        },
        testMethodDelete(params) {
            return http.fetchPost(apiUrl.testMethodDelete + '/' + params);
        },
        getTestMethodList(params) {
            return http.fetchPost(apiUrl.getTestMethodList, params);
        },
        getTestMethodConfirmCList(params) {
            return http.fetchGet(apiUrl.getTestMethodConfirmCList, params);
        },
        testMethodInfo(params) {
            return http.fetchGet(apiUrl.testMethodInfo + '/' + params);
        },
        testMethodUpdate(params) {
            return http.fetchPost(apiUrl.testMethodUpdate, params);
        },
    },
}

export default api;